/* You can add global styles to this file, and also import other style files */

@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;
@import 'swiper/css/bundle';
@import 'libs/bau365/ui-shell/src/lib/styles/colors';
@import 'libs/bau365/ui-shell/src/lib/styles/devices';
@import 'libs/bau365/ui-shell/src/lib/styles/advertisements';
@import 'libs/shared/ui-typography/src/lib/styles/typography-selfservice';
@import 'libs/shared/ui-grid/src/lib/styles/flexgrid';
@import 'libs/shared/ui-material-override/src/lib/styles/material-override-selfservice';

html,
body {
  height: 100%;
}

html {
  body {
    margin: 0;
  }
}

.tooltip__custom {
  --mdc-plain-tooltip-container-color: #0e1c2f !important;

  border-radius: 12px;
}
