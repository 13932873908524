@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

.mdc-button {
  border-radius: 5px;
  font-weight: 500;
  height: auto !important;
  min-height: 40px;

  &.mdc-button--unelevated {
    &.mat-accent {
      --mdc-filled-button-label-text-color: #{map.get(theme.$primary-palette, 'contrast', 200)};
    }
  }

  &.mdc-button--outlined {
    &.mat-accent {
      --mdc-outlined-button-label-text-color: #{map.get(theme.$accent-palette, 200)};
      --mdc-outlined-button-outline-color: #{map.get(theme.$accent-palette, 200)};

      background-color: #fff;
      border-radius: 5px;
      border-width: 2px;
      font-weight: 500;
    }
  }
}
