@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 200;

  // See: https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,-25
  src: url('../fonts/material-symbols-cdn-400.woff2') format('woff2');
}

@font-face {
  font-family: 'Material Symbols Outlined Fill';
  font-style: normal;
  font-weight: 200;

  // See: https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,-25
  src: url('../fonts/material-symbols-cdn-400-fill.woff2') format('woff2');
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-weight: 500;

  // See: https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,500,0,-25
  src: url('../fonts/material-symbols-cdn-500.woff2') format('woff2');
}

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Symbols Outlined';
  font-feature-settings: 'liga';
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variation-settings: 'FILL' 0;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1;
  text-rendering: optimizelegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;

  &.material-icons--zoomed {
    height: 24px;
    position: relative;
    top: -2px;
    transform: scale(1.3);
    width: 24px;
  }
}
